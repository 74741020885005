import GATSBY_COMPILED_MDX from "/home/runner/work/splide-site/splide-site/docs/v3/guides/transition/index.mdx";
import React from 'react';
import {graphql, Link} from 'gatsby';
import Page from '../components/scenes/Page/Page/Page';
import {MDXProvider} from '@mdx-js/react';
import {Type} from '../components/components/API/Type/Type';
import {Example} from '../components/components/Example/Example';
import {Button} from '../components/components/Button/Button';
import {Box} from '../components/components/Box/Box';
import {Params} from '../components/components/API/Params/Params';
import {LinkTable} from '../components/components/LinkTable/LinkTable';
import {LinkTo} from '../components/components/LinkTo/LinkTo';
import {Icon} from '../components/components/Icon/Icon';
import {ParamTable} from '../components/components/ParamTable/ParamTable';
import {Accessor} from '../components/components/API/Accessor/Accessor';
import {Key} from '../components/components/Key/Key';
import {VideoPlayer} from '../components/components/VideoPlayer/VideoPlayer';
import {SEO} from '../components/seo/SEO/SEO';
const shortcodes = {
  Box,
  Button,
  Link,
  Params,
  Type,
  LinkTable,
  LinkTo,
  Example,
  Icon,
  ParamTable,
  Accessor,
  Key,
  VideoPlayer
};
function Document({data, pageContext, children}) {
  const doc = data.mdx;
  return React.createElement(Page, {
    heading: doc.frontmatter.title,
    localizedHeading: doc.frontmatter.localizedTitle,
    desc: doc.frontmatter.desc,
    pageContext: pageContext,
    isDocument: true
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Document, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head({data}) {
  const {frontmatter} = data.mdx;
  return React.createElement(SEO, {
    title: frontmatter.localizedTitle || frontmatter.title,
    description: frontmatter.desc,
    isArticle: true
  });
}
export const query = graphql`
  query($slug: String! $language: String!) {
    mdx( fields: { slug: { eq: $slug } language: { eq: $language } } ) {
      frontmatter {
        desc
        title
        localizedTitle
      }
    }
  }
`;
